/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Route, Switch, Redirect } from 'react-router-dom';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import { SERVER_ENDPOINT_API } from '../constants/index';

import routes from 'routes.js';
import axios from '../variables/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let ps;

const apiEndPoint = `${SERVER_ENDPOINT_API}/getConfigData`;
class Dashboard extends React.Component {
  state = {
    backgroundColor: 'blue'
  };
  mainPanel = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    this.fetchConfig();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.body.classList.toggle('perfect-scrollbar-on');
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleColorClick = color => {
    this.setState({ backgroundColor: color });
  };

  fetchConfig = async () => {
    const response = await axios.post(apiEndPoint);
    this.setState({ configData: response.data });
    this.setState({ isNewRowSequency: response.data.isNewRowSequency });
  };
  render() {
    return (
      <div className="wrapper">
        <ToastContainer />;
        <Sidebar
          {...this.props}
          routes={routes}
          backgroundColor={this.state.backgroundColor}
          isNewRowSequency={this.state.isNewRowSequency}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar {...this.props} allConfig={this.state.configData} />
          <Switch>
            {routes.map((prop, key) => {
              return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            })}
            <Redirect from="/admin" to="/admin/collections/listing" />
          </Switch>
          <Footer fluid />
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleColorClick={this.handleColorClick}
        /> */}
      </div>
    );
  }
}

export default Dashboard;
