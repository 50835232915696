import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from 'reactstrap';
import { useStateValue } from '../../context/RuleContext';
import axios from '../../variables/axios';
import { AUTH_SERVICE_URL } from '../../constants';
import { getCurrentUser, removeFromCookie } from '../../utils/index';

const STORES = {
  1: { storeId: 1, name: 'KSA English Store' },
  3: { storeId: 3, name: 'KSA Arabic Store' },
  7: { storeId: 7, name: 'UAE English Store' },
  11: { storeId: 11, name: 'UAE Arabic Store' }
};

const envs = ['prod', 'qa', 'qastgprodvalue', 'magnetoautoupload', 'uat', 'dev'];

export default ({ allConfig = {} }) => {
  const [isOpen, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [storeToggleOpen, seStoreToggleOpen] = useState(false);
  const [storeId, setStoreId] = useState(window.localStorage.storeId || 1);
  const [env, setEnv] = useState(
    window.localStorage.env || (process.env.CUROFY_APP_NODE_ENV === 'production' ? 'prod' : 'qa')
  );
  const [user, setUser] = useState({});

  const {
    rules: [, ruleDispatch],
    configData: [configState, configDispatch]
  } = useStateValue();

  const history = useHistory();

  const sidebarToggle = React.createRef();

  const dropdownToggle = e => {
    setDropdownOpen(!dropdownOpen);
  };
  const storeToggle = e => {
    seStoreToggleOpen(!storeToggleOpen);
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    sidebarToggle.current.classList.toggle('toggled');
  };

  useEffect(() => {
    if (Object.keys(allConfig).length) {
      configDispatch({ type: 'SET_CONFIG_DATA', data: allConfig });
    }
  }, [allConfig]);

  const handleLogout = async () => {
    try {
      const userData = getCurrentUser();
      if (userData) {
        const { uuid, token } = userData;
        console.log('userUuid::::', uuid);
        removeFromCookie();
        const logoutResp = await axios.post(
          `${AUTH_SERVICE_URL}/logout`,
          { uuid: uuid },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token.trim()
            }
          }
        );
      }
      window.location.reload(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSwitchEnv = env => {
    ruleDispatch({ type: 'SET_ENV', data: { env } });
    setEnv(env);
    window.localStorage.env = env;
  };
  const handleSwitchStores = storeId => {
    ruleDispatch({ type: 'SET_STORE', data: { storeId } });
    setStoreId(storeId);
    window.localStorage.storeId = storeId;
  };
  const location = useLocation();
  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      setUser(user);
    } else {
      window.location.href = '/app/login';
    }
  }, [location.pathname]);
  return (
    <Navbar color={'transparent'} expand="lg" className={'navbar-absolute fixed-top'}>
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button type="button" ref={sidebarToggle} className="navbar-toggler" onClick={() => openSidebar()}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand onClick={() => history.replace('/')} href="javascript:;">
            CUROFY
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={() => {}}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <Dropdown nav isOpen={storeToggleOpen} toggle={e => storeToggle(e)}>
              <DropdownToggle caret nav>
                {env.toUpperCase()}
                {/* : {STORES[storeId].name} */}
              </DropdownToggle>
              <DropdownMenu right>
                <small style={{ paddingLeft: 15 }}>ENV</small>
                {envs.map(ev => {
                  return (
                    <DropdownItem key={'ev-' + ev} tag="a" onClick={() => handleSwitchEnv(ev)}>
                      {ev.toUpperCase()}
                    </DropdownItem>
                  );
                })}
                {/* <hr />
                <small style={{ paddingLeft: 15 }}>STORE</small>
                {Object.keys(STORES).map(sId => {
                  return (
                    <DropdownItem
                      tag="a"
                      onClick={() => handleSwitchStores(sId)}
                    >
                      {STORES[sId].name}
                    </DropdownItem>
                  );
                })} */}
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={dropdownOpen} toggle={e => dropdownToggle(e)}>
              <DropdownToggle caret nav>
                <i className="now-ui-icons users_single-02" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a">{user?.email}</DropdownItem>
                <DropdownItem tag="a" onClick={handleLogout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
        <div style={{ position: 'absolute', left: '50%', top: '17px', marginLeft: '-50px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ color: '#FFF', fontSize: '12px', marginRight: '5px' }}>
              {localStorage.getItem('LOCATION')}
            </span>
            <img
              src={`https://dev-bucket.stylifashion.com/flags/flag_${localStorage
                .getItem('LOCATION')
                .toLowerCase()}.png`}
              style={{ height: '40px', width: 'auto' }}
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
};
